type TOptions = {
  links?: [string];
  classNameAttr?: string;
  classAttr?: string;
  linksBlank?: [boolean];
};

type TNestedLinksParserPlugin = {
  type: 'postProcessor';
  name: String;
  process: (value: string, _key: string, options: TOptions) => string;
};

export const nestedLinksParserPlugin: TNestedLinksParserPlugin = {
  type: 'postProcessor',
  name: 'nestedLinksParser',
  process: function (value, _key, options) {
    const linksBlank = options.linksBlank;
    const links = options?.links;
    const classNameAttr = options?.classNameAttr ? ` className="${options?.classNameAttr}"` : '';
    const classAttr = options?.classAttr ? ` class="${options?.classAttr}"` : '';

    let matchIndex = 0;
    return value.replace(/<a>/g, () => {
      const subStitute = `<a${classNameAttr}${classAttr}${linksBlank?.[matchIndex] ? ' target="_blank"' : ''} href="${
        links?.[matchIndex] || ''
      }">`;

      matchIndex++;
      return subStitute;
    });
  },
};

export default nestedLinksParserPlugin;
